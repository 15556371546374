// components/SkeletonLoader.js
import styles from '../../styles/SkeletonLoader.module.css'; // Create a CSS module for styling

const SkeletonLoader = () => {
  return (
    <div className={styles.skeleton}>
      {/* Add your skeleton styles or elements here */}
    </div>
  );
};

export default SkeletonLoader;
